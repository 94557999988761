import React from 'react';

export default function Guidelines() {
  return (
    <div>
        {/* Banner Section */}
        <div className="section inner_page_banner" style={{ backgroundColor: "#ff8436" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="banner_title">
                  <h3>Author Guidelines</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content Section */}
        <div className="section layout_padding about_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="full padding_left_15" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                  <p>
                    <b>Before submitting a paper, kindly check the guidelines</b> to determine whether your manuscript is within the scope of the journal. Authors are suggested to go through all guidelines including editorial and review process, copyright and licence, plagiarism policies, publication ethics etc.
                    <br/><br/>

                    <b>Submission Checklist</b><br/>
                    Before submitting a paper/manuscript to the journal for publication, authors should utilize this list to perform one last review:
                    <br/>- The manuscript is as per the scope of the journal
                    <br/>- The manuscript is following the structure specific to the type of article
                    <br/>- One author has been designated as the corresponding author with contact details i.e. E-mail address and Full postal address
                    <br/>- The manuscript includes the Keywords, all figures (include relevant captions), all tables (including titles, description, footnotes). Ensure all figure and table numbers are cited in the text
                    <br/>- Manuscript has been 'spell checked' and 'grammar checked'
                    <br/>- All references mentioned in the Reference List are cited in the text, and vice versa. References follow the pattern of the journal
                    <br/>- Appropriate permission and acknowledgement are taken for all the copyright materials used in the paper
                    <br/>- Journal policies detailed in this guide have been reviewed
                    <br/><br/>

                    <b>Aims, Objectives and Scope</b><br/>
                    International Journal of Nutrition & Lifestyle (Int. J. Nut. Life.) ensures the scrutinized publication of high-quality research papers. It’s a scientific research journal devoted to original articles based on Experimental / Community / Clinical Nutrition & Lifestyle related research or practices that contribute to holistic Health & Wellness of human beings.
                    <br/><br/>
                    The prime objective of the International Journal of Nutrition & Lifestyle is to provide a platform to highlight quality research by eminent researchers in the field of Nutrition and Lifestyle contributing to Holistic Health and Wellness. We aim to facilitate students & researchers from academic & clinical field to share & publish their knowledge & scientifically proven practises with fellow researchers as well as policy makers.
                    <br/><br/>
                    <ul>
                      <li>The scope covers all researches related to holistic wellness that includes physical, mental, social, emotional, spiritual wellness of a human being leading to a higher Happiness Quotient</li>
                      <li>The Journal covers all types of research work in the field of Dietary practices / guidelines, Diet and Disease, Dietary Surveys, Clinical Nutritional Interventions, Public Health Nutrition, Nutrigenomics, Molecular Nutrition, Food Processing and Technology, New Product Development, Value Added Food Products, Nutritional Epidemiology, Weight Management, Organic & Agri-based Food Researches, Food Biotechnology & all relevant Health based studies</li>
                      <li>Lifestyle based researches including Yoga, Exercise, Herbal medicinal researches, et. al. are also included</li>
                    </ul>
                    <br/><br/>

                    <b>Types of articles</b><br/>
                    <b>Original Research Papers:</b> An original research paper is a primary source that presents the results of an original study, experiment or investigation carried out by the authors. The purpose of an original research paper is to present new findings or insights into a particular research question or problem, based on data or evidence collected by the authors. All papers submitted to journal must have an abstract (up to 300 words), and conclusion that is brief and clear (Abstract and conclusion should not have any reference).
                    <br/><br/>
                    <b>Review Research Papers:</b> A review research paper is a secondary source that provides a comprehensive and critical analysis of existing literature on a particular research topic or question. The purpose of a review research paper is to summarize and synthesize the current state of knowledge on a particular topic, identify gaps or inconsistencies in the literature, and highlight areas where further research is needed.
                    <br/><br/>
                    <b>Short communications:</b> A short communication is a brief article that presents original research or clinical studies that are significant and need to be published quickly.
                    <br/><br/>

                    <b>Format of the Manuscript</b><br/>
                    <b>1. Type of Manuscript:</b> While preparing a manuscript, authors should refer to manuscript preparation guidelines. Generally, we need to adhere to the following guidelines:
                    <br/>a) <b>Original Papers:</b> The paper is generally about 5000 words including tables, figures, references. Typically a table or figure is equivalent to 150 words while a photograph is equivalent to 300 words. The Paper should include: Abstract (approx 250 words) containing the main results and conclusions; up to 10 keywords that accurately identify the paper's subject, purpose and focus; an Introduction with clear objectives. Materials and Methods with experimental detail, sources of material must be given and statistical methods must be specified by reference, unless non-standard. Results should be presented properly using well-designed tables and/or figures; data should not be repeated in tables & figures, appropriate statistical data should be given. If the editor finds inadequate mathematical treatment of the data, the manuscript will be returned un-refereed. Discussion should cover the implications and consequences and the conclusions should be concise; brief Acknowledgements / References.
                    <br/>b) <b>Review Articles:</b> It should be fewer than 5000 words. These are concise, critical but constructive and conclusive written for non-specialists.
                    <br/><br/>

                    <b>2. Manuscript format & structure</b><br/>
                    <b>a)</b> The language of publication is English. Authors should ensure that the paper is clearly written in standard, scientific English language
                    <br/><br/>
                    <b>b)</b> Standard Usage, Abbreviations and Units: Spelling and hyphenation should conform to The Concise Oxford English Dictionary. Statistics and measurements should always be given in figures, e.g. 10 min., except when the number begins with a sentence. When the number does not refer to a unit of measurement it should be spelt in full unless it is 100 or greater. Abbreviations should be used sparingly and only if a lengthy name or expression is repeated throughout the manuscript, and never in the title. The abbreviated name or expression should be cited in full at first usage, followed by the accepted abbreviation in parentheses.
                    <br/><br/>
                    <b>c)</b> Metric SI units should generally be used
                    <br/><br/>
                    <b>d)</b> Main Text: Text files should be formatted double-spaced with no hyphenation. It should be in Arial, font size 12. Headings should be in 14 Arial, bold & subheadings in 12 Arial bold.
                    <br/><br/>
                    <b>e)</b> Structure
                    <br/><br/>
                    <b>Structure of Original Research Paper</b><br/>
                    - <b>Title Page:</b> The title page should carry an informative title that reflects the content - Title should be in font Arial, size 16 bold. The Title should be followed by the names of the authors, and the place(s) where the work was carried out. The full postal address with email address of the indicated corresponding author must be given. Author’s names should be in Arial 12 bold with an asterisk on corresponding author’s name. Affiliation, address & email id should be written in Arial, 12 unbold.
                    <br/><br/>
                    - <b>Abstract:</b> An abstract is a brief summary of a research paper or article that provides an overview of the study's objectives, methods, results, interpretation, and conclusions. It should be in approximately 250 words.
                    <br/><br/>
                    - <b>Keywords:</b> At least 4-5 keywords should be mentioned. If using abbreviations, add only those that are firmly established in the field.
                    <br/><br/>
                    - <b>Introduction:</b> State the objectives of the work and provide an adequate background, avoiding a detailed literature survey or a summary of the results.
                    <br/><br/>
                    - <b>Research Methodology:</b> Provide a clear and detailed description of the materials and methods used in your research paper. This includes details of the study area, study design, sampling method, data collection procedures, statistical analysis techniques, and any ethical considerations taken into account. Use clear and concise language, avoid jargon, and provide references to relevant literature or resources.
                    <br/><br/>
                    - <b>Results and Discussion:</b> It is important to present the results of your research paper in a clear and organized manner in the Results section. This section should include tables, figures, and statistics that support your findings. The Discussion section should interpret and contextualize the results, highlight their significance, and discuss any limitations of the study. Additionally, this section should connect the findings to the research question or hypothesis, and suggest future research directions.
                    <br/><br/>
                    - <b>Conclusions:</b> The main conclusions of the study should be presented in a short Conclusion section
                    <br/><br/>
                    - <b>Acknowledgements:</b> List here those individuals who provided help during the research (e.g., providing language help, writing assistance or proofreading the article, etc.).
                    <br/><br/>
                    - <b>Funding Sources:</b> Mention the funding details of the study. If no funding has been provided for the research, it is recommended to include the following sentence: This research did not receive any specific grant from funding agencies in the public, commercial, or not-for-profit sectors.
                    <br/><br/>
                    - <b>References:</b> The references should be in full containing names of all the authors with the title of the paper. References should be written in Arial, font size 10 References in the text should be cited by the authors' names followed by year of publication, unless there are three or more authors when only the first author's name is quoted followed by et al. e.g. Smith et al. (1999) or Jones and Smith (2000). Add a, b, c etc. to distinguish between two or more references with the same author name and year date (e.g. Jones 1999 a, b). At the end of the paper references should be listed in the order as mentioned in text following the Vancouver style of referencing (https://library.viu.ca/citing/vancouver) as shown below -
                    Gupta V, Nagar R. Physico - chemical and acceptability of rabadi (a fermented soya flour product) as affected by cooking and fermentation time. Int. J. of Food Sci. Technol. 2008; 49: 939 - 943.
                    <br/><br/>
                    It is essential that all citations and references are carefully checked before submission, as mistakes may cause delays.
                    <br/><br/>

                    <b>Structure for Review Research Papers</b><br/>
                    - <b>Title Page:</b> The title page should carry an informative title that reflects the content - Title should be in font Arial, size 16 bold. The Title should be followed by the names of the authors, and the place(s) where the work was carried out. The full postal address with email address of the indicated corresponding author must be given. Author’s names should be in Arial 12 bold with an asterisk on corresponding author’s name. Affiliation, address & email id should be written in Arial, 12 unbold.
                    <br/><br/>
                    - <b>Abstract:</b> An abstract for review papers is a brief summary of a literature review or meta-analysis that provides an overview of the research topic, the methods used to gather and analyze the literature, and the key findings and conclusions of the review.
                    <br/><br/>
                    - <b>Keywords:</b> At least 4-5 keywords should be mentioned.
                    <br/><br/>
                    - <b>Introduction:</b> Provide an overview of the research topic and the purpose of the review. Define the scope of the review, and explain the importance of the topic.
                    <br/><br/>
                    - <b>Methods:</b> Describe the methods used to identify and select the studies included in the review. This may include a systematic literature search, and criteria for study selection.
                    <br/><br/>
                    - <b>Further headings depending on the content of the article:</b> Summarize and synthesize the findings of the studies included in the review. Organize the results into themes or categories, and use tables, figures, and statistics to present the findings.
                    <br/><br/>
                    Interpret and contextualize the findings, and relate them to the research question or hypothesis. Discuss the strengths and weaknesses of the studies included in the review, and identify gaps in the literature.
                    <br/><br/>
                    <b>Conclusion:</b> Summarize the main findings and conclusions of the review, and highlight the implications for practice and future research.
                    <br/><br/>
                    <b>Acknowledgement</b><br/>
                    <b>References:</b> Include a list of all the references cited in the review.
                    <br/><br/>

                    <b>Tables, Figures and Figure Legends</b><br/>
                    Each table & figure should have proper title & cited in the text with table no. & figure no. Axes on graphs should always be titled and include units where appropriate.
                    <br/><br/>

                    <b>General guidelines</b><br/>
                    Articles are accepted on the strict understanding that the material in whole or in part has not been, nor is being, considered for publication elsewhere.
                    <br/><br/>

                    <b>Ethical guidelines</b><br/>
                    <ul>
                      <li>Please give a statement describing any ethical guidelines under which your research was carried out. Where ethics approval was not required a simple statement that “Ethics approval was not required for this research.” is sufficient. This should be placed below the Acknowledgements section.</li>
                      <li>Avoiding Plagiarism: The journal may use plagiarism detection software to screen the submissions. If plagiarism is identified, the research article will be rejected.</li>
                      <li>Authorship consent: Corresponding / Submitting author is believed to have had taken prior consent from all authors before submitting a manuscript to the Journal. Authorship should be limited to those who have made a significant contribution to the conception, design, execution, or interpretation of the reported study, drafting the article or revising it critically for important intellectual content in accordance with COPE guidelines. Do not “gift” authorship to those who did not contribute to the paper. Editors and Publishers cannot be asked to be involved in an authorship dispute. The authors may be asked to sign an ‘Author Certificate’ having the statement that all the authors have approved the manuscript and have contributed significantly to the paper</li>
                      <li>Acknowledgements: Under acknowledgements please mention contributors to the article other than the authors. Sources of research material should be named along with their location and duly credited.</li>
                      <li>Conflict of Interest: Authors are requested to disclose any possible / applicable conflict of interest that may or may not arise.</li>
                      <li>Funding source: Authors are requested to mention the source of funding for their research while submitting a paper.</li>
                      <li>Appeal of Decision: The Editor’s decision on a paper shall be final & binding to all and cannot be appealed.</li>
                      <li>Permissions: If all or parts of previously published illustrations are used, permission must be obtained from the concerned copyright holder(s).</li>
                      <li>Copyright: If the manuscript is accepted, the corresponding / submitting author is required to email a copyright consent to the Journal on behalf of all authors on the paper.</li>
                    </ul>
                    <br/><br/>

                    <b>Manuscript submission guidelines</b><br/>
                    <ul>
                      <li>The paper needs to be submitted online in a single WORD software file through the journal’s website https://www.researchthrive.com/submitarticle or by email at contact@researchthrive.com</li>
                      <li>Before submission of manuscript, author need to give consent about plagiarism, conflict of interest and to ensure that all publication ethics have been followed in their research and manuscript preparation</li>
                      <li>After signing consent form, the author need to submit the manuscript through automatically directed Google form in which they need to fill details of the manuscript including title, author’s names, abstract, keywords etc. along with names of 2 potential reviewers with their contact details. The reviewers should not be from the same institution</li>
                    </ul>
                    <br/><br/>

                    <b>Editorial & Peer Review Process</b><br/>
                    After submission of a manuscript to the journal, it is primarily screened by the editor-in-chief to ensure that it meets the journal's scope, format requirements, plagiarism, originality and linguistic quality. If the manuscript does not meet the requirements, it is returned to the author without further review. If the manuscript passes the initial screening, it is carried forward for review process. The manuscript is sent for peer review in a double blind review process to the experts in the field. The reviewers evaluate the manuscript for its scientific rigor, validity, and significance. On the basis of reviewer’s comments, the manuscript is either rejected or accepted for publication as such/ after minor revision/ after major revisions. If the manuscript is sent for revision, then the editor evaluates the revisions and accepts/rejects the manuscript. The manuscript is copyedited and typeset, sent to the corresponding author for proof reading and the final version is published online after receiving author’s consent
                    <br/><br/>

                    <b>Author Response</b><br/>
                    - Please include a detailed point by point response to the decision letter with your re-submission. In order to help editors and reviewers focus on your edits please highlights key changes after acceptance
                    <br/>- Upon acceptance, the manuscript will be forwarded to the Production Editor who is responsible for the production of the journal
                    <br/>- Before publication, corresponding author will receive article for proof corrections. Proofs must be returned to the Production Editor within three days of receipt. Each article will be given a DOI at the time of publication.
                    <br/>- Any change in the author’s designation and affiliation should be notified at the earliest
                    <br/>- Please make all the correspondence by email & always quote your manuscript number with your mail
                    <br/><br/>

                    <b>Initial Acceptance-Cum-Invoice</b><br/>
                    After receiving the corrected paper from the authors an initial acceptance letter-cum-invoice for the APC charges will be sent to the authors.
                    <br/><br/>

                    <b>Final Acceptance Letter</b><br/>
                    After receiving the APC charges a final acceptance letter indicating the month and the year (Vol and Issue No.) in which the paper is likely to be published will be sent to the authors.
                    <br/><br/>

                    <b>After Acceptance</b><br/>
                    - <b>Editorial Corrections:</b> The paper will be sent for corrections (if any) after the editorial review by the copy editors. To ensure a fast publication process of the article, we kindly ask authors to provide us with their corrected manuscript within a week.
                    <br/>- <b>Proof Corrections:</b> The galley proof of the manuscript will be sent to the corresponding author before the release of the issue. Please use this proof only for checking the typesetting, editing, completeness and correctness of the text, tables and figures. No addition or deletion of tables and figures as well as a significant change in the text will be permitted at this stage. Proofreading is solely the responsibility of the authors.
                    <br/><br/>

                    <b>Article Processing Charges</b><br/>
                    International Journal of Nutrition & lifestyle is an open-access journal and is not funded by any institution or government. As such, the journal does not receive subscription fees or access fees from readers. To cover the costs of maintaining the journal, authors are required to pay a handling fee for the processing of their articles.
                    <br/><br/>
                    <ul>
                      <li>No Submission Charges: Authors are not charged for submitting their manuscripts</li>
                      <li>Article Processing/Publication Charges (APC): Authors are required to pay APC charges after their articles have undergone the peer-review process and been accepted for publication. Article processing charges are INR1499.00 from Indian authors while $99.00 from foreign authors</li>
                      <li>Number of authors in the paper: The journal does not encourage gift authorship. The standard number of authors in the paper is 5 authors. Each author above 5 shall be charged 10% extra of the total APC charges</li>
                      <li>The specific APC charges are communicated to authors after the peer-review process. Please note that APC charges are non-refundable once the paper is accepted or if it is retracted from the website due to exceptional circumstances</li>
                    </ul>
                    <br/><br/>

                    <b>Correspondence</b><br/>
                    All the correspondence should be made by e-mail at contact@researchthrive.com and the manuscript number should always be quoted while corresponding for a particular paper.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
