import React from 'react'
import EndPageBanner from './endPageBanner'

export default function About(props) {
  return (
    <div>
      {/* <!-- Start Banner --> */}
      <div className="section inner_page_banner removeSpace" style={{ backgroundColor: "#ff8436" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner_title">
                <h3>About Us</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Banner --> */}

      <div className="section layout_padding about_bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="full paddding_left_15" style={{ textAlign: "justify", textJustify: "inter-word" }}>
                <p>
                  <b>Aims, Objectives & Scope</b><br />
                  The <i>International Journal of Nutrition &amp; Lifestyle</i> (Int. J. Nut. Life.) ensures the scrutinized publication of high-quality research papers. It’s a scientific research journal devoted to original articles based on Experimental, Community, Clinical Nutrition &amp; Lifestyle related research or practices that contribute to holistic health &amp; wellness of human beings.<br /><br />

                  The prime objective of the <i>International Journal of Nutrition &amp; Lifestyle</i> is to provide a platform to highlight quality research by eminent researchers in the field of Nutrition and Lifestyle contributing to holistic health and wellness. We aim to facilitate students &amp; researchers from academic &amp; clinical fields to share &amp; publish their knowledge &amp; scientifically proven practices with fellow researchers as well as policymakers.<br /><br />

                  <b>Scope:</b><br />
                  - The scope covers all research related to holistic wellness, including physical, mental, social, emotional, and spiritual wellness of a human being, leading to a higher Happiness Quotient.<br />
                  - The journal covers all types of research work in the fields of Dietary practices/guidelines, Diet and Disease, Dietary Surveys, Clinical Nutritional Interventions, Public Health Nutrition, Nutrigenomics, Molecular Nutrition, Food Processing and Technology, New Product Development, Value Added Food Products, Nutritional Epidemiology, Weight Management, Organic &amp; Agri-based Food Researches, Food Biotechnology, and all relevant Health-based studies.<br />
                  - Lifestyle-based research, including Yoga, Exercise, Herbal medicinal research, etc., is also included.<br /><br />

                  <b>Language of Journal:</b> English<br />
                  <b>Starting Year:</b> 2021<br />
                  <b>Format of Publication:</b> Online Only<br />
                  <b>Frequency of Publication:</b> Quarterly (March, June, September, December)<br /><br />

                  <b>Editorial & Peer Review Process:</b><br />
                  After submission of a manuscript to the journal, it is primarily screened by the editor-in-
                  chief to ensure that it meets the journal's scope, format requirements, plagiarism,
                  originality and linguistic quality. If the manuscript does not meet the requirements, it is
                  returned to the author without further review. If the manuscript passes the initial screening,
                  it is carried forward for review process. The manuscript is sent for peer review in a double
                  About Us
                  blind review process to the experts in the field. The reviewers evaluate the manuscript for
                  its scientific rigor, validity, and significance. On the basis of reviewer’s comments, the
                  manuscript is either rejected or accepted for publication as such/ after minor revision/ after
                  major revisions. If the manuscript is sent for revision, then the editor evaluates the
                  revisions and accepts/rejects the manuscript. The manuscript is copyedited and typeset,
                  sent to the corresponding author for proof reading and the final version is published online
                  after receiving author’s consent<br /><br />
                  <b>Copyright and License</b><br />
                  - Researchthrive Publications follows the CC BY license
                  <a href='https://creativecommons.org/licenses/by/4.0/'> <u> https://creativecommons.org/licenses/by/4.0/</u>  </a> (International Journal of Nutrition &
                  Lifestyle © 2024 by Researchthrive Publications is licensed under Creative
                  Commons Attribution 4.0 International).
                  <br /> - The CC BY license is the most common open license available and is considered
                  the industry 'gold standard' for open access.
                  <br />- This license allows users to copy and redistribute the material in any medium or
                  format, and to alter, transform, or build upon the material, including for commercial
                  use, provided that the original author is credited.
                  <br />- Copyright on any open access article in the journal ‘International Journal of Nutrition
                  & Lifestyle’ published by Researchthrive Publications is retained by the author(s).
                  <br />- Authors grant Researchthrive Publications a license to publish the article and
                  identify itself as the original publisher.<br /><br />
                  <b>Plagiarism Policy</b><br />
                  Plagiarism is using someone else's work without attribution, paraphrasing someone else's
                  work without proper citation, and self-plagiarism is when an author tries to re-publish his
                  previously published articles/data in some other journal.
                  International Journal of Nutrition & Lifestyle demands a similarity index report from the
                  corresponding author at the time of submission of manuscript and accepts an article for
                  publication only when similarity index is less than 15%. <br /><br />
                  <b>Processing and Publication Charges</b><br />
                  International Journal of Nutrition & Lifestyle is an open-access journal and is not funded by
                  any institution or government. As such, the journal does not receive subscription fees or
                  access fees from readers. Initially there were no charges till 3 years as a part of journal’s
                  launching period to attract more manuscripts, but now authors have to submit “Article
                  processing charges (APC)” after acceptance of their manuscript before it is sent to
                  publication. Article processing charges are INR 1499.00 from Indian authors while $99.00
                  from foreign authors to cover the cost for maintenance of the journal. There are NO
                  charges for submission of a manuscript. These APC charges are non refundable once the
                  paper is accepted or if it is retracted from the website due to exceptional circumstances.<br /><br />
                  <b>Publication Ethics</b><br />
                  Publications form the basis for both new research and the application of findings, they can
                  affect not only the research community but also, indirectly, society at large. Researchers,
                  therefore, have a responsibility to ensure that their publications are honest, clear,
                  accurate, complete and balanced, and should avoid misleading, selective or ambiguous
                  reporting.
                  The journal supports the code of conduct set out by the Committee on Publication Ethics
                  (COPE) (<a href='https://publicationethics.org/'><u>https://publicationethics.org/</u></a>).
                  Following are some publication ethics as per the COPE guidelines that the authors need to
                  follow:
                  <br /><br />
                  <b>Soundness and reliability</b><br />
                  The research submitted for publication, should have been conducted in an ethical and
                  responsible manner and must follow all relevant legislation. Authors should use
                  appropriate methods of data analysis and display (and, if needed, seek and follow
                  specialist advice on this). Authors should take collective responsibility for their work and for
                  the content of their publications. Authors should check their publications carefully at all
                  stages to ensure methods and findings are reported accurately. Authors should carefully
                  check calculations, data presentations, typescripts/submissions and proofs.
                  <br />
                  <br />

                  <b>Originality and Acknowledgement of Sources</b>
                  <br></br>The authors should make sure that their writing is original, and if they have utilized
                  someone else's ideas or words, they should make sure that they have quoted or cited
                  them correctly and, if required, acquired permission. Plagiarism, including duplicate
                  publication of the author's own work, in whole or in part without proper citation is not
                  allowed by the journal. Plagiarism of any sort is forbidden and is considered unethical
                  behaviour.<br />
                  <br />

                  <b>Multiple, Redundant or Concurrent Publication</b>
                  <br></br>
                  Duplicate or redundant submission refers to the same manuscript (or the same data) that
                  is submitted to different journals at the same time. Authors should not submit a manuscript
                  that has been already published or submitted elsewhere. Such manuscript will be
                  immediately rejected. The Journal does not view the following uses of a work as prior
                  publication: publication in the form of an abstract; publication as an academic thesis;
                  publication as an electronic preprint.
                  <br />
                  <br />
                  <b>Authorship of the Paper</b>
                  <br></br>
                  Authorship should be limited to those who have made a significant contribution to the
                  conception, design, execution, or interpretation of the reported study, drafting the article or
                  revising it critically for important intellectual content in accordance with COPE
                  guidelines. Do not “gift” authorship to those who did not contribute to the paper. Editors
                  and Publishers cannot be asked to be involved in an authorship dispute. The authors may
                  be asked to sign an ‘Author Certificate’ having the statement that all the authors have
                  approved the manuscript and have contributed significantly to the paper
                  <br />
                  <br />
                  <b>Hazards to Human or Animal Subjects</b>
                  <br></br>
                  If the work involves human or animal trials, then the author needs to mention about the
                  approval of their Institutional Ethics Committee.
                  <br />
                  <br />

                  <b>Conflicts of Interest</b>
                  <br></br>
                  Authors, reviewers and editors all have a duty to report possible conflicts of interest. In the
                  case of authors, they should declare anything that may have influenced the research or
                  could influence the review process or the publication of the article. If the author is unsure
                  whether it’s a conflict of interest, always check with the editor or publisher ahead of
                  submission. Possible conflicts of interest include:

                  <ul>
                    <li>- A prior relationship between author and editor
                    </li>
                    <li>- A financial or personal interest in the outcomes of the research
                    </li>
                    <li>- Undisclosed financial support for the research by an interested third party
                    </li>
                    <li>- A financial or personal interest in the suppression of the research
                    </li>
                    <li>- A pending patent
                    </li>
                  </ul>
                  When submitting the work, the authors should include a note outlining any financial
                  support received for the research from outside sources and drawing attention to any other
                  potential conflicts of interest.
                  <br />
                  <br />
                  <b>Editors and Reviewers</b>
                  <br></br>
                  Editors and reviewers should decline to be involved with a submission when they
                  <ul>
                    <li>- Have a recent publication or current submission with any author
                    </li>
                    <li>- Have a recent publication or current submission with any author
                    </li>
                    <li>- Collaborate or recently collaborated with any author
                    </li>
                    <li>- Have a close personal connection to any author
                    </li>
                    <li>- Have a financial interest in the subject of the work
                    </li>
                    <li>- Feel unable to be objective
                    </li>
                  </ul>
                  Editors and reviewers must declare if they have previously discussed the manuscript with
                  the authors.<br />
                  <br />
                  <b>Notification of Fundamental Errors</b>
                  <br></br>
                  It’s the responsibility of the author to promptly notify journal’s editor or publisher if he/she
finds a significant error or inaccuracy in his/her own published work and cooperate with the
editor to retract or correct the paper.                  <br />
                  <br />
                  <b>Open Access Statement of the Journal</b>
                  <br></br>
                  This is an open access journal which means that all the content is freely available to the
readers without any charge to the user or his/her institution. Users are allowed to read,
download, copy, distribute, print, search, or link to the full texts of the articles, or use them
for any other lawful purpose, without asking prior permission from the publisher or the
author, provided proper attribution to the original work is given. This is in accordance with
the BOAI definition of open access.                  <br />
                  <br />
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EndPageBanner />
    </div>
  )
}
